@import "base/mixins/media";
@import "base/mixins/mixins";
@import "base/colors";

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@include max-screen(1199) {
	section {
		padding: 70px 0; }

	section.welcome {
		height: 730px;
		.welcome-content {
			max-width: 380px; } }

	.sidebar.sidebar-right {
		padding-left: 0; }

	ul.list-contact {
		ul.submenu li {
			padding-right: 0; } }

	.navbar-expand .navbar-nav .nav-link {
		padding: 0 20px; }

	.carousel-thumbs {
		.carousel-item {
			height: 450px; }
		.carousel-indicators li .item {
			height: 90px; } } }

/* ==========================================================================
 Media Queries Style (более)
 *========================================================================== */
@include min-screen(991) {
	ul.list-contact {
		ul.submenu {
			display: block !important; } }

	.sidebar.sidebar-right {
		margin-bottom: -30px;
		overflow: hidden; } }

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@include max-screen(991) {
	section {
		padding: 60px 0; }

	.title {
    margin-bottom: 50px; }

	.editor {
		@include font-size(20px); }

	.greybox-body {
		@include font-size(20px);
		width: 90%; }

	.offcanvas-header {
		display:block {} }

	.header-contact {
		padding-right: 60px; }

	.btn-mobile-open {
		display: block; }

	.widget-contact {
		.widget-title {
			@include font-size(20px);
			padding: 12px 0 15px;
			margin-bottom: 0;
			cursor: pointer; } }

	ul.list-contact {
		@include font-size(18px);
		margin-bottom: 40px;
		ul.submenu {
			display: none;
			margin: 20px 0 10px; }
		ul.submenu li {
			padding-bottom: 15px; }
		.svg {
			top: 0; } }

	section.contact {
		padding-bottom: 0; }

	.contact-map {
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: -10px;
		iframe {
			height: 520px; } }

	.content,
	.content-top {
		margin-top: 0;
		padding-top: 40px; }

	section.welcome {
		height: 680px;
		.welcome-content {
			max-width: 330px; } }

	section.source {
		.title {
			padding: 10px 0; } }

	ul.list-about {
		.icon {
			width: 100px;
			height: 100px;
			line-height: 100px; }
		svg {
			width: 50px;
			height: 50px; } }

	.carousel-thumbs {
		padding-top: 0;
		flex-direction: column;
		.carousel-inner {
			flex: 0 0 100%;
			width: 100%;
			-ms-flex-order: 1;
			order: 1;
			margin-bottom: 20px; }
		.carousel-item {
			height: 290px; }
		.carousel-indicators {
			flex-direction: row;
			justify-content: center;
			flex: 0 0 100%;
			width: 100%;
			-ms-flex-order: 2;
			order: 2; }
		.carousel-indicators li {
			width: 10px;
			height: 10px;
			margin: 0 7px;
			background-color: #F0F0F0;
			@include br(30px); }
		.carousel-indicators li:hover,
		.carousel-indicators li.active {
			background-color: $link-green; }
		.carousel-indicators li:not(:last-child) {
			margin-bottom: 0; }
		.carousel-indicators li .item {
			display: none; }
		.carousel-indicators li .item img {
			display: none; } }

	.flag-icons {
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		flex-wrap: nowrap !important;
		.icons-img {
			width: 25%;
			margin-right: 20px;
			margin-bottom: 0; }
		.icons-text {
			-ms-flex-positive: 1 !important;
			flex-grow: 1 !important;
			-ms-flex-item-align: center !important;
			align-self: center !important; } }

	.footer {
		.header-contact {
			padding-right: 0; } } }

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@include max-screen(767) {
	section {
		padding: 50px 0; }

	main {
		padding-bottom: 20px; }

	.mb-60 {
		margin-bottom: 50px !important; }

	.title {
    margin-bottom: 40px; }

	.editor {
		@include font-size(18px); }

	.greybox {
		padding: 20px; }

	.greybox-body {
		width: 100%;
		@include font-size(18px);
		padding-left: 0;
		.svg {
			width: 30px;
			height: 30px;
			position: relative;
			display: inline-block;
			vertical-align: middle;
			margin-top: -10px; } }

	.contact-map {
		margin-bottom: -30px;
		iframe {
			height: 420px; } }

	ul.list-detail {
		text-align: center;
		margin-bottom: 10px;
		.item {
			padding: 10px 0 0;
			background-color: #ffffff;
			@include br(0); }
		span {
			display: block; }
		img {
			position: relative;
			top: 0;
			left: auto;
			display: inline-block;
			margin-bottom: 3px; } }

	.content,
	.content-top {
		margin-top: 0;
		padding-top: 30px; }

	.content {
		.editor {}
		ul.list-attribute {}
		.greybox {
			padding: 25px 20px; }
		.greybox .greybox-body {
			@include font-size(18px);
			padding-left: 0;
			min-height: 48px;
			.svg {
				width: 30px;
				height: 30px;
				position: relative;
				display: inline-block;
				vertical-align: middle;
				margin-top: -10px; } } }

	section.welcome-image {
		height: 60px;
		background-image: none !important; }

	section.welcome-no-image {
		padding-top: 60px; }

	section.welcome {
		& .row {
			height: 100%; }
		.welcome-content {
			padding-top: 70px;
			width: 90%;
			max-width: none;
			text-align: center;
			margin: 0 auto;
			.btn.btn-light {
				@include absolut(auto,auto,40px,50%);
				transform: translate(-50%, 0); } } }

	.header {
		padding: 10px 0 !important;
		.navbar-brand {
			margin-right: 0; }
		.header-contact {
			padding-right: 40px; }
		ul.list-header-contact {
			li.navbar-mail {
				display: none; }
			li.navbar-phone {
				span {
					display: none; } }
			.svg-phone {
				width: 25px !important;
				height: 25px !important;
				margin-bottom: 2px; } }
		a.navbar-brand img {
			max-width: 150px !important; } }

	ul.list-about {
		margin-bottom: 20px;
		.icon {
			img {
				width: 50px;
				height: 50px; } }
		li {
			@include font-size(24px); } }

	section.source {
		.title {
			padding: 0;
			margin-bottom: 30px; } }

	.flag-icons {
		@include font-size(20px); }

	.footer {
		a.navbar-brand {
			display: none; }
		.header-contact {
			width: 100% !important; } }

	.footer-nav {
		padding-bottom: 20px;
		ul.list-header-contact {
			display: block;
			text-align: center;
			li:not(:last-child) {
				margin-right: 20px; }
			li {
				display: inline-block;
				vertical-align: top;
				.svg {
					display: none; } } } }

	.footer-copy {
		border-top: 0;
		padding-top: 0;
		ul.list-social {
			border-bottom: 1px solid #DDDDDD;
			padding-bottom: 25px;
			text-align: center; }
		.text-copy {
			padding-top: 18px; } } }

/* ==========================================================================
 Media Queries Style (более)
 *========================================================================== */
@include min-screen(575) {
	ul.list-about {
		ul.submenu {
			display: block !important; } } }
/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@include max-screen(575) {

	.title {
    margin-bottom: 30px; }

	.text-info {
		@include font-size(18px);
		padding-left: 0;
		min-height: auto;
		margin-bottom: 0;
		.svg {
			width: 30px;
			height: 30px;
			position: relative;
			display: inline-block;
			vertical-align: baseline;
			margin-right: 10px; } }

	ul.list-detail {
		.item {
			@include font-size(16px); } }

	section.welcome {
		.welcome-content {
			width: 100%; } }

	ul.list-about {
		text-align: left;
		margin-bottom: 30px;
		li.active .svg-download {
			transform: rotate(-180deg); }
		li {
			@include font-size(22px);
			width: 100%;
			max-width: none;
			padding-left: 75px;
			margin-bottom: 0;
			&:before {
				content: "";
				width: calc(100% - 30px);
				height: 1px;
				border-bottom: 1px solid #DFDBDB;
				@include absolut(auto,15px,0,15px); } }
		.icon {
			width: 40px;
			height: 40px;
			line-height: 40px;
			background-color: inherit;
			@include absolut(15px,auto,auto,15px);
			margin-bottom: 0;
			@include br(0);
			img {
				width: 38px;
				height: 38px;
				margin-bottom: 0; } }
		.svg.svg-download {
			width: 18px;
			height: 18px;
			@include absolut(27px,15px,auto,auto); }
		.tabs-js {
			display: block;
			cursor: pointer; }
		.tabs-css {
			display: none; }
		.title {
			padding-top: 20px;
			padding-bottom: 25px;
			margin-bottom: 0; }
		ul.submenu {
			display: none;
			li {
				border-bottom: 0;
				padding-left: 0;
				margin-bottom: 30px; }
			li:before {
				display: none; } } } }

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@include max-screen(480) {

	.flag-icons {
		@include font-size(16px);
		margin-bottom: 15px;
		.icons-img {
			width: 24px;
			min-width: 24px;
			margin-right: 12px; } }

	ul.list-detail {
		.item {
			@include font-size(12px); } }

	section.source {
		.hidd {
			margin-bottom: -15px; } }

	.footer-nav {
		ul.list-header-contact li {
			@include font-size(16px); } } }

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@include max-screen(320) {

	section.welcome {
		height: 570px;
		@include jpg(bg/welcome-320,no-repeat,top,center);
		.welcome-content {
			padding-top: 90px; } } }
