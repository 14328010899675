@charset "UTF-8";
/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@media screen and (max-width: 1199px) {
  section {
    padding: 70px 0;
  }
  section.welcome {
    height: 730px;
  }
  section.welcome .welcome-content {
    max-width: 380px;
  }
  .sidebar.sidebar-right {
    padding-left: 0;
  }
  ul.list-contact ul.submenu li {
    padding-right: 0;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding: 0 20px;
  }
  .carousel-thumbs .carousel-item {
    height: 450px;
  }
  .carousel-thumbs .carousel-indicators li .item {
    height: 90px;
  }
}

/* ==========================================================================
 Media Queries Style (более)
 *========================================================================== */
@media screen and (min-width: 991px) {
  ul.list-contact ul.submenu {
    display: block !important;
  }
  .sidebar.sidebar-right {
    margin-bottom: -30px;
    overflow: hidden;
  }
}

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@media screen and (max-width: 991px) {
  section {
    padding: 60px 0;
  }
  .title {
    margin-bottom: 50px;
  }
  .editor {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .greybox-body {
    font-size: 20px;
    font-size: 1.25rem;
    width: 90%;
  }
  .header-contact {
    padding-right: 60px;
  }
  .btn-mobile-open {
    display: block;
  }
  .widget-contact .widget-title {
    font-size: 20px;
    font-size: 1.25rem;
    padding: 12px 0 15px;
    margin-bottom: 0;
    cursor: pointer;
  }
  ul.list-contact {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 40px;
  }
  ul.list-contact ul.submenu {
    display: none;
    margin: 20px 0 10px;
  }
  ul.list-contact ul.submenu li {
    padding-bottom: 15px;
  }
  ul.list-contact .svg {
    top: 0;
  }
  section.contact {
    padding-bottom: 0;
  }
  .contact-map {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -10px;
  }
  .contact-map iframe {
    height: 520px;
  }
  .content,
  .content-top {
    margin-top: 0;
    padding-top: 40px;
  }
  section.welcome {
    height: 680px;
  }
  section.welcome .welcome-content {
    max-width: 330px;
  }
  section.source .title {
    padding: 10px 0;
  }
  ul.list-about .icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  ul.list-about svg {
    width: 50px;
    height: 50px;
  }
  .carousel-thumbs {
    padding-top: 0;
    flex-direction: column;
  }
  .carousel-thumbs .carousel-inner {
    flex: 0 0 100%;
    width: 100%;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 20px;
  }
  .carousel-thumbs .carousel-item {
    height: 290px;
  }
  .carousel-thumbs .carousel-indicators {
    flex-direction: row;
    justify-content: center;
    flex: 0 0 100%;
    width: 100%;
    -ms-flex-order: 2;
    order: 2;
  }
  .carousel-thumbs .carousel-indicators li {
    width: 10px;
    height: 10px;
    margin: 0 7px;
    background-color: #F0F0F0;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
  }
  .carousel-thumbs .carousel-indicators li:hover,
  .carousel-thumbs .carousel-indicators li.active {
    background-color: #3F8E29;
  }
  .carousel-thumbs .carousel-indicators li:not(:last-child) {
    margin-bottom: 0;
  }
  .carousel-thumbs .carousel-indicators li .item {
    display: none;
  }
  .carousel-thumbs .carousel-indicators li .item img {
    display: none;
  }
  .flag-icons {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap !important;
  }
  .flag-icons .icons-img {
    width: 25%;
    margin-right: 20px;
    margin-bottom: 0;
  }
  .flag-icons .icons-text {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .footer .header-contact {
    padding-right: 0;
  }
}

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@media screen and (max-width: 767px) {
  section {
    padding: 50px 0;
  }
  main {
    padding-bottom: 20px;
  }
  .mb-60 {
    margin-bottom: 50px !important;
  }
  .title {
    margin-bottom: 40px;
  }
  .editor {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .greybox {
    padding: 20px;
  }
  .greybox-body {
    width: 100%;
    font-size: 18px;
    font-size: 1.125rem;
    padding-left: 0;
  }
  .greybox-body .svg {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-top: -10px;
  }
  .contact-map {
    margin-bottom: -30px;
  }
  .contact-map iframe {
    height: 420px;
  }
  ul.list-detail {
    text-align: center;
    margin-bottom: 10px;
  }
  ul.list-detail .item {
    padding: 10px 0 0;
    background-color: #ffffff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }
  ul.list-detail span {
    display: block;
  }
  ul.list-detail img {
    position: relative;
    top: 0;
    left: auto;
    display: inline-block;
    margin-bottom: 3px;
  }
  .content,
  .content-top {
    margin-top: 0;
    padding-top: 30px;
  }
  .content .greybox {
    padding: 25px 20px;
  }
  .content .greybox .greybox-body {
    font-size: 18px;
    font-size: 1.125rem;
    padding-left: 0;
    min-height: 48px;
  }
  .content .greybox .greybox-body .svg {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-top: -10px;
  }
  section.welcome-image {
    height: 60px;
    background-image: none !important;
  }
  section.welcome-no-image {
    padding-top: 60px;
  }
  section.welcome .row {
    height: 100%;
  }
  section.welcome .welcome-content {
    padding-top: 70px;
    width: 90%;
    max-width: none;
    text-align: center;
    margin: 0 auto;
  }
  section.welcome .welcome-content .btn.btn-light {
    position: absolute;
    top: auto;
    left: 50%;
    right: auto;
    bottom: 40px;
    transform: translate(-50%, 0);
  }
  .header {
    padding: 10px 0 !important;
  }
  .header .navbar-brand {
    margin-right: 0;
  }
  .header .header-contact {
    padding-right: 40px;
  }
  .header ul.list-header-contact li.navbar-mail {
    display: none;
  }
  .header ul.list-header-contact li.navbar-phone span {
    display: none;
  }
  .header ul.list-header-contact .svg-phone {
    width: 25px !important;
    height: 25px !important;
    margin-bottom: 2px;
  }
  .header a.navbar-brand img {
    max-width: 150px !important;
  }
  ul.list-about {
    margin-bottom: 20px;
  }
  ul.list-about .icon img {
    width: 50px;
    height: 50px;
  }
  ul.list-about li {
    font-size: 24px;
    font-size: 1.5rem;
  }
  section.source .title {
    padding: 0;
    margin-bottom: 30px;
  }
  .flag-icons {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .footer a.navbar-brand {
    display: none;
  }
  .footer .header-contact {
    width: 100% !important;
  }
  .footer-nav {
    padding-bottom: 20px;
  }
  .footer-nav ul.list-header-contact {
    display: block;
    text-align: center;
  }
  .footer-nav ul.list-header-contact li:not(:last-child) {
    margin-right: 20px;
  }
  .footer-nav ul.list-header-contact li {
    display: inline-block;
    vertical-align: top;
  }
  .footer-nav ul.list-header-contact li .svg {
    display: none;
  }
  .footer-copy {
    border-top: 0;
    padding-top: 0;
  }
  .footer-copy ul.list-social {
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 25px;
    text-align: center;
  }
  .footer-copy .text-copy {
    padding-top: 18px;
  }
}

/* ==========================================================================
 Media Queries Style (более)
 *========================================================================== */
@media screen and (min-width: 575px) {
  ul.list-about ul.submenu {
    display: block !important;
  }
}

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@media screen and (max-width: 575px) {
  .title {
    margin-bottom: 30px;
  }
  .text-info {
    font-size: 18px;
    font-size: 1.125rem;
    padding-left: 0;
    min-height: auto;
    margin-bottom: 0;
  }
  .text-info .svg {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
    vertical-align: baseline;
    margin-right: 10px;
  }
  ul.list-detail .item {
    font-size: 16px;
    font-size: 1rem;
  }
  section.welcome .welcome-content {
    width: 100%;
  }
  ul.list-about {
    text-align: left;
    margin-bottom: 30px;
  }
  ul.list-about li.active .svg-download {
    transform: rotate(-180deg);
  }
  ul.list-about li {
    font-size: 22px;
    font-size: 1.375rem;
    width: 100%;
    max-width: none;
    padding-left: 75px;
    margin-bottom: 0;
  }
  ul.list-about li:before {
    content: "";
    width: calc(100% - 30px);
    height: 1px;
    border-bottom: 1px solid #DFDBDB;
    position: absolute;
    top: auto;
    left: 15px;
    right: 15px;
    bottom: 0;
  }
  ul.list-about .icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: inherit;
    position: absolute;
    top: 15px;
    left: 15px;
    right: auto;
    bottom: auto;
    margin-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }
  ul.list-about .icon img {
    width: 38px;
    height: 38px;
    margin-bottom: 0;
  }
  ul.list-about .svg.svg-download {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 27px;
    left: auto;
    right: 15px;
    bottom: auto;
  }
  ul.list-about .tabs-js {
    display: block;
    cursor: pointer;
  }
  ul.list-about .tabs-css {
    display: none;
  }
  ul.list-about .title {
    padding-top: 20px;
    padding-bottom: 25px;
    margin-bottom: 0;
  }
  ul.list-about ul.submenu {
    display: none;
  }
  ul.list-about ul.submenu li {
    border-bottom: 0;
    padding-left: 0;
    margin-bottom: 30px;
  }
  ul.list-about ul.submenu li:before {
    display: none;
  }
}

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@media screen and (max-width: 480px) {
  .flag-icons {
    font-size: 16px;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  .flag-icons .icons-img {
    width: 24px;
    min-width: 24px;
    margin-right: 12px;
  }
  ul.list-detail .item {
    font-size: 12px;
    font-size: 0.75rem;
  }
  section.source .hidd {
    margin-bottom: -15px;
  }
  .footer-nav ul.list-header-contact li {
    font-size: 16px;
    font-size: 1rem;
  }
}

/* ==========================================================================
 Media Queries Style (менее)
 *========================================================================== */
@media screen and (max-width: 320px) {
  section.welcome {
    height: 570px;
    background-image: url("../images/bg/welcome-320.jpg");
    background-repeat: no-repeat;
    background-position: top center;
  }
  section.welcome .welcome-content {
    padding-top: 90px;
  }
}
